import React, { useState } from "react";
import Loader from "./Loader";

export default function About() {
  const [isLoad, setIsLoad] = useState(true);
  setTimeout(() => {
    setIsLoad(false);
  }, 500);
  return isLoad ? (
    <Loader />
  ) : (
    <section className="p-6 bg-gray-50 text-gray-800 rounded-lg shadow-lg m-4">
      <h1 className="text-3xl font-bold text-center mb-6">About Us</h1>

      <p className="text-lg mb-4">
        <strong>Welcome to Serene Vista!</strong>
        <br />
        At Serene Vista, we are dedicated to providing a tranquil online space
        where you can explore and enhance your well-being. Our mission is to
        offer resources and support for personal growth and relaxation, helping
        you to achieve a balanced and peaceful lifestyle.
      </p>

      <h2 className="text-2xl font-semibold mb-3">Our Story</h2>
      <p className="mb-4">
        Founded in 2024, Serene Vista was created to offer a sanctuary for
        individuals seeking to improve their mental and emotional well-being. We
        believe that serenity and personal growth are essential for a fulfilling
        life, and we are committed to providing the tools and guidance needed to
        achieve them.
      </p>

      <h2 className="text-2xl font-semibold mb-3">Our Team</h2>
      <p className="mb-4">
        Our team includes experts in wellness, personal development, and
        mindfulness who are passionate about helping others find peace and
        balance. We bring a wealth of knowledge and experience to our services,
        ensuring that you receive the highest quality support.
      </p>

      <h2 className="text-2xl font-semibold mb-3">Our Services</h2>
      <ul className="list-disc list-inside mb-4">
        <li className="mb-2">
          <strong>Wellness Resources:</strong> Access a variety of resources
          designed to support your mental and emotional well-being.
        </li>
        <li className="mb-2">
          <strong>Guided Meditations:</strong> Enjoy guided meditations and
          relaxation techniques to help you find calm and clarity.
        </li>
        <li className="mb-2">
          <strong>Personal Development:</strong> Discover tools and strategies
          for personal growth and achieving a balanced lifestyle.
        </li>
      </ul>

      <p className="text-lg">
        Thank you for choosing Serene Vista. We are honored to support you on
        your journey to serenity and personal fulfillment.
      </p>
    </section>
  );
}
