import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Games from "../Games.json";
import Loader from "./Loader";

const shuffleArray = (array) => {
  return array.sort(() => Math.random() - 0.5);
};

export default function Home() {
  const [isLoad, setIsLoad] = useState(true);
  const [randomGames, setRandomGames] = useState([]);

  useEffect(() => {
    const shuffledGames = shuffleArray([...Games]).slice(0, 20);
    setRandomGames(shuffledGames);
  }, []);

  setTimeout(() => {
    setIsLoad(false);
  }, 500);

  return isLoad ? (
    <Loader />
  ) : (
    <section className="flex items-center justify-center p-8 bg-gray-100 dark:bg-gray-800">
      <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 gap-9 w-full">
        {randomGames.map((data, index) => (
          <div
            className="relative rounded-lg overflow-hidden shadow-lg shadow-gray-600 transform transition duration-300 hover:scale-95 hover:shadow-xl bg-white dark:bg-gray-700"
            key={index}
          >
            <img className="w-full" src={data.logo} alt={data.title} />

            {/* Play Icon Container */}
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-65 opacity-0 transition-opacity duration-300 hover:opacity-100">
              <Link
                to={`/single?slug=${data.slug}`}
                className="text-white text-xl"
              >
                <div className="bg-[#ae2012] rounded-lg flex justify-center items-center">
                  <p className="px-4 py-2 font-medium text-md tracking-[1px]">
                    Play
                  </p>
                </div>
              </Link>
            </div>

            {/* Title */}
            <div className="flex items-center justify-center px-3 py-2">
              <span className="text-xl font-bold text-gray-900 dark:text-gray-100">
                {data.title}
              </span>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
