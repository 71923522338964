import { Link, useLocation } from "react-router-dom";
import React, { useState } from "react";
export default function Navbar() {
  const location = useLocation();
  const currentPath = location.pathname.split("/")[1];
  const [activeLink, setActiveLink] = useState(
    currentPath !== "" ? currentPath : "home"
  );
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = (link) => {
    setIsMenuOpen(true);
    setActiveLink(link);
  };
  return (
    <>
      <header
        className="bg-[#ef233c] text-white p-2"
        style={{
          background:
            "radial-gradient(circle, rgba(174,32,18,1) 0%, rgba(155,34,38,1) 100%)",
        }}
      >
        <nav className="border-gray-200">
          <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto md:mb-0 mb-2 p-4">
            <Link
              to="/"
              className="flex items-center space-x-3 rtl:space-x-reverse"
            >
              <img src="/navbar.png" className="h-15" alt="serenevista.site" />
              {/* <span className="text-2xl font-semibold whitespace-nowrap">Web-game</span> */}
            </Link>

            {/* Mobile menu button */}
            <button
              data-collapse-toggle="navbar-search"
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-white rounded-lg md:hidden hover:bg-[#d43f32] focus:outline-none focus:ring-2 focus:ring-gray-200"
              aria-controls="navbar-search"
              aria-expanded={isMenuOpen ? "true" : "false"}
              onClick={handleMenuToggle}
            >
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>

            {/* Navigation menu */}
            <div
              id="navbar-search"
              className={`absolute top-16 inset-x-0 z-40 bg-[#ae2012] text-white rounded-lg md:static md:flex md:items-center md:justify-between md:w-auto md:bg-transparent ${
                isMenuOpen ? "block" : "hidden"
              }`}
            >
              <ul
                className="flex flex-col p-4   font-medium rounded-lg md:flex-row md:space-x-8 md:p-0 md:mt-0 md:!bg-none"
                style={{
                  background:
                    "radial-gradient(circle, rgba(174,32,18,1) 0%, rgba(155,34,38,1) 100%)",
                }}
              >
                <li>
                  <Link
                    to="/"
                    className={`block py-2 px-3 rounded-md hover:bg-[#881215] md:hover:bg-transparent md:hover:bg-[#881215] ${
                      activeLink === "home" ? "font-bold" : "text-white"
                    }`}
                    onClick={() => handleLinkClick("home")}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about"
                    className={`block py-2 px-3 rounded-md hover:bg-[#881215] md:hover:bg-transparent md:hover:bg-[#881215] ${
                      activeLink === "about" ? "font-bold" : "text-white"
                    }`}
                    onClick={() => handleLinkClick("about")}
                  >
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    to="/privacy-policy"
                    className={`block py-2 px-3 rounded-md hover:bg-[#881215] md:hover:bg-transparent md:hover:bg-[#881215] ${
                      activeLink === "privacy-policy"
                        ? "font-bold"
                        : "text-white"
                    }`}
                    onClick={() => handleLinkClick("privacy-policy")}
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to="/term-condition"
                    className={`block py-2 px-3 rounded-md hover:bg-[#881215] md:hover:bg-transparent md:hover:bg-[#881215] ${
                      activeLink === "term-condition"
                        ? "font-bold"
                        : "text-white"
                    }`}
                    onClick={() => handleLinkClick("term-condition")}
                  >
                    Term Condition
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact-us"
                    className={`block py-2 px-3 rounded-md hover:bg-[#881215] md:hover:bg-transparent md:hover:bg-[#881215] ${
                      activeLink === "contact-us" ? "font-bold" : "text-white"
                    }`}
                    onClick={() => handleLinkClick("contact-us")}
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}
